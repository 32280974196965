import { h, Component } from "preact";
// @ts-ignore
import style from "./footer.scss";
// @ts-ignore
import icon_fb from "~/assets/images/icon-facebook.svg";
// @ts-ignore
import icon_ig from "~/assets/images/icon-instagram.svg";
// @ts-ignore
import icon_yt from "~/assets/images/icon-youtube.svg";
// @ts-ignore
import icon_truck from "~/assets/images/icons/truck.svg";
// @ts-ignore
import icon_support from "~/assets/images/icons/support.svg";
// @ts-ignore
import icon_lock from "~/assets/images/icons/lock.svg";
// @ts-ignore
import img_anton from "~/assets/images/characters/anton.png";

import { Link } from "../link/link";
import { bookStore } from "../../bookstore";
import { Subscribe } from "../subscribe/subscribe";

export class Footer extends Component {
  render() {
    return (
      <footer>
        <div class={style.prefooter}>
          <div class={style.hurtige}>
            <div>
              <img src={icon_truck} alt="levering" />
              <h4>Levering til pakkeshop</h4>
              Vi leverer til nærmeste pakkeshop inden for 3-5 hverdage via DAO365
            </div>
            <div>
              <img src={icon_support} alt="support" />
              <h4>Hurtig support</h4>
              Vi hjælper og besvarer alle dine spørgsmål inden for 1 hverdag
            </div>
            <div>
             <img src={icon_lock} alt="sikker" />
              <h4>Sikker handel</h4>
              Vi bruger en sikker forbindelse og betaling foregår gennem en sikker betalingsservice
            </div>
          </div>
          <div>
              <Subscribe />
              <img class={style.anton} src={img_anton} />
          </div>
        </div>
        <div class={style.slope}></div>
        <div class={style.footer}>
          <div class={style.content}>
            <nav class={style.block}>
              <strong>Bøger</strong>
              {
                bookStore.productInfo.map(i => 
                  <div>
                    <Link url={i.url}>{i.title}</Link>
                  </div>
                )
              }
            </nav>
            <div class={style.block}>
              <strong>Oplysninger</strong>
              <div>
                <Link url="om-os">Om os</Link>
              </div>
              <div>
                <Link url="faq">Spørgsmål og svar</Link>
              </div>
              <div>
                <Link url="betingelser">Handelsbetingelser</Link>
              </div>
            </div>
            <div class={style.block}>
              <strong>Kontakt</strong>
              <div>
                <a class={style.email} href="mailto:patrick.littlestories@gmail.com">
                  patrick.littlestories@gmail.com
                </a>
              </div>
            </div>
            
            <div class={style.block}>
              <strong>Følg os</strong>
              <div class={style.socialMedia}>
                <a href="https://www.facebook.com/BooksByLittleStories/">
                  <img src={icon_fb} alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/booksbylittlestories/">
                  <img src={icon_ig} alt="Instagram" />
                </a>
                {/* <img src={icon_yt} alt="Youtube" /> */}
              </div>
            </div>
          </div>
        </div>
        <div class={style.bottomStrip}>
          © Little Stories 2019 | CVR: 40106960
        </div>
      </footer>
    );
  }
}