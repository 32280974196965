import { h, Component } from "preact";
import { Spinner } from "../spinner/spinner";
// @ts-ignore
import style from "./pageview.scss";


class PageViewProps {
  page: any
}
class PageViewState {
  comp: any
  loading: boolean
}

export class PageView extends Component<PageViewProps, PageViewState> {
  constructor(props) {
    super(props);
    this.loadComponent = this.loadComponent.bind(this);
    this.startLoad = this.startLoad.bind(this);
    this.state = {
      comp:null,
      loading: false
    };
  }

  componentDidMount() {
    this.startLoad();
  }

  componentDidUpdate(oldProps:PageViewProps) {
    if(this.props.page != oldProps.page) {
      this.startLoad();
    }
  }

  startLoad() {
    document.getElementById("container").scrollTop = 0;
    this.setState({ loading:true });
    this.loadComponent();
  }

  async loadComponent() {
    const comp = (await this.props.page).default;
    //setTimeout(() => this.setState({comp, loading:false}), 2000)
    this.setState({comp, loading:false});
  }

  render() {
    return (
      <main class={style.page}>
        {h(this.state.comp, this.props)}
        {
          this.state.loading &&
          <div class={style.overlay}>
            <div>
              <Spinner />
            </div>
          </div>
        }
      </main>
    );
  }
}