export type Item = string

enum Tag { physical };
export enum Format { paperback, ebook }

export class Price {
    value: number
    constructor(value:number = 0) {
        this.value = value;
    }
    toString() {
        return `Kr. ${this.value/100},-`
    }
    add(p:Price) {
        return new Price(this.value + p.value);
    }
}

export class Customer {
    email: string   = ""
    name: string    = ""
    address: string = ""
    zipcode: string = ""
    city: string    = ""
    mobile: string  = ""
}

export class OrderTotal {
    subtotal: Price = new Price(0);
    shipping: Price = new Price(0);
    discount: Price = new Price(0);

    // @ts-ignore
    get total() {
        return this.subtotal.add(this.discount).add(this.shipping);
    }
    // @ts-ignore
    get discountedSubtotal() {
        return this.subtotal.add(this.discount);
    }

    constructor(items:Item[], coupons:string[]) {
        items.forEach(id => {
            const prod = getProduct(id);

            if(prod.tags.includes(Tag.physical))
                this.shipping = new Price(3900);

            this.subtotal = this.subtotal.add(prod.price);
        });

        if(coupons.includes('UDEN-FRAGT'))
            this.shipping = new Price(0);

        if(coupons.includes('FREJA'))
            this.discount = new Price(-this.subtotal.value*0.1)
    }
}

export class Order {
    id: number = -1
    items: Item[] = []
    coupons: string[] = []
    keys: string[] = []
    pickup: PickupPoint = null;
    customer: Customer = new Customer();

    calculateOrderTotal() {
        return new OrderTotal(this.items, this.coupons);
    }

    needsShipping() {
        if(this.coupons.includes('UDEN-FRAGT'))
            return false

        for(const id of this.items) {
            const prod = getProduct(id);
            if(prod.tags.includes(Tag.physical))
                return true;
        }
        return false;
    }

    getEbooks() {
        return this.items.filter(id => getProduct(id).format === Format.ebook);
    }
}

export class PickupPoint {
    name: string
    address: string
    zipcode: number
    city: string
    id: number
};

export class Product {
    id: string
    name: string
    price: Price
    format: Format
    tags: Tag[]
    cover: string
}

const products : Product[] = [
    {
        id: "bien_bo",
        name: "Bien Bo",
        price: new Price(6000),
        format: Format.paperback,
        tags: [Tag.physical],
        cover: 'https://firebasestorage.googleapis.com/v0/b/littlestories-dk.appspot.com/o/images%2Fcovers%2FBien%20Bo.jpg?alt=media&token=0331c113-e23d-465b-b68a-bad0fabaa43f'
    },
    {
        id: "bien_bo.ebook",
        name: "Bien Bo",
        price: new Price(3000),
        format: Format.ebook,
        tags: [],
        cover: 'https://firebasestorage.googleapis.com/v0/b/littlestories-dk.appspot.com/o/images%2Fcovers%2FBien%20Bo.jpg?alt=media&token=0331c113-e23d-465b-b68a-bad0fabaa43f'
    },
    {
        id: "krokodillen_karl",
        name: "Krokodillen Karl",
        price: new Price(6000),
        format: Format.paperback,
        tags: [Tag.physical],
        cover: 'https://firebasestorage.googleapis.com/v0/b/littlestories-dk.appspot.com/o/images%2Fcovers%2FKrokodillen%20Karl.jpg?alt=media&token=e60a6aec-28a0-4283-8285-5a55142cd9e2'
    },
    {
        id: "krokodillen_karl.ebook",
        name: "Krokodillen Karl",
        price: new Price(3000),
        format: Format.ebook,
        tags: [],
        cover: 'https://firebasestorage.googleapis.com/v0/b/littlestories-dk.appspot.com/o/images%2Fcovers%2FKrokodillen%20Karl.jpg?alt=media&token=e60a6aec-28a0-4283-8285-5a55142cd9e2'
    },
    {
        id: "fisken_freja",
        name: "Fisken Freja",
        price: new Price(6000),
        format: Format.paperback,
        tags: [Tag.physical],
        cover: 'https://firebasestorage.googleapis.com/v0/b/littlestories-dk.appspot.com/o/images%2Fcovers%2FKrokodillen%20Karl.jpg?alt=media&token=e60a6aec-28a0-4283-8285-5a55142cd9e2'
    },
    {
        id: "fisken_freja.ebook",
        name: "Fisken Freja",
        price: new Price(3000),
        format: Format.ebook,
        tags: [],
        cover: 'https://firebasestorage.googleapis.com/v0/b/littlestories-dk.appspot.com/o/images%2Fcovers%2FKrokodillen%20Karl.jpg?alt=media&token=e60a6aec-28a0-4283-8285-5a55142cd9e2'
    }
];

export function getProduct(id:string) {
    return products.find(p => p.id === id);
}

export function validateCoupon(coupon:string) {
    switch(coupon) {
        case "UDEN-FRAGT":
        case "FREJA":
            return true;
    }
    return false;
}