import { h, Component } from "preact";
import { bookStore, BookInfo } from "../../bookstore";

import { Link } from "../../components/link/link";
import { BuyButton } from "../../components/buybutton/buybutton";
import { DiscountBanner } from "../../components/discountbanner/discountbanner";
// @ts-ignore
import style from "./overview.scss";
// @ts-ignore
import nyhedBanner from '~assets/images/nyhed.svg'

function BookItem({info}:{info:BookInfo}) {
  return (
    <div class={style.bookItem} >
      <div class={style.bookLink}>
        <Link url={this.props.info.url}>
          <div class={style.coverContainer}>
            <img class={style.cover} src={info.image} alt="Bog" />
            {info.ids.book == 'fisken_freja' &&
              <img class={style.nyhed} src={nyhedBanner} />
            }
          </div>
        </Link>
        <div>
          <Link url={this.props.info.url}>
            <div class={style.title} >
              <h2>{info.title}</h2>
              <h4>{info.subtitle}</h4>
            </div>
          </Link>
          <BuyButton info={info} />
        </div>
      </div>
    </div>
  );
}

export default class Overview extends Component {
  render() {
    return (
      <div class={style.container}>
        <DiscountBanner />
        <div class={style.bg}></div>
        <div class={style.overview}>
          { bookStore.productInfo.map(i => <BookItem info={i} />) }
        </div>
      </div>
    );
  }
}