import { h, Component } from 'preact'
// @ts-ignore
import style from "./bestilling.scss"
import { ItemTable } from '../../components/itemtable/itemtable'
import { bookStore } from '../../bookstore'
import { PickUpMap } from '../../components/pickupmap/pickupmap'
import { Payment } from '../../components/payment/payment'
import { Spinner } from '../../components/spinner/spinner'
import { PickupPoint } from '../../../functions/src/shared/shared';

class BestillingState {
  pickup: PickupPoint
};

export default class Bestilling extends Component<{}, BestillingState> {
  constructor() {
    super()
    if(bookStore.order.items.length == 0)
      bookStore.link("/");
    if(!bookStore.termsAccepted)
      bookStore.link("/oplysninger");
    bookStore.termsAccepted = false; // reset

    this.onPickupSelect = this.onPickupSelect.bind(this);

    this.state = {
      pickup: null
    };
  }

  onPickupSelect(point:PickupPoint) {
    bookStore.order.pickup = point;
    this.setState({pickup:point});
  }

  render() {
    let payStyle = this.state.pickup == null ? "opacity: 0.5;cursor:default;pointer-events:none;" : "";
    let order = bookStore.order;

    const needsShipping = order.needsShipping();

    return (
      <div class={style.bestilling}>
        <h1>Din bestilling</h1>
        {
          needsShipping ?
          <div>
            <h3>Levering og betaling</h3>
            <div class={style.map}>
              <div class={style.mapSpinner}><Spinner /></div>
              <PickUpMap onSelect={this.onPickupSelect} />
            </div>
            <div class={style.info}>
              <h4>Faktureringsadresse</h4>
              <div>{order.customer.name}</div>
              <div>{order.customer.address}</div>
              <div>{order.customer.zipcode} {order.customer.city}</div>
              <div><br />
                {order.customer.email}
                <br />
                {order.customer.mobile}
              </div>
              <hr />
              <h4>Leveringsadresse</h4>
              {
                this.state.pickup == null ?
                  <div>Vælg pakkeshop på kortet</div>
                :
                <div>
                  <div>{this.state.pickup.name}</div>
                  <div>{this.state.pickup.address}</div>
                  <div>{this.state.pickup.zipcode} {this.state.pickup.city}</div>
                </div>
              }
              <div class={style.pay} style={payStyle}>
                <Payment />
              </div>
            </div>
          </div>
          :
          <div>
            <h3>Betaling</h3>
            <div class={style.infoOnly}>
              <div class={style.infoOnlyAddress}>
                <h4>Faktureringsadresse</h4>
                <div>{order.customer.name}</div>
                <div>{order.customer.address}</div>
                <div>{order.customer.zipcode} {order.customer.city}</div>
                <div><br />{order.customer.email}</div>
              </div>
              <div class={style.infoOnlyPay}>
                <Payment />
              </div>
            </div>
          </div>
        }
        <div class={style.items}>
          <h3>Dine varer</h3>
          <ItemTable linkItems={false} />
        </div>
      </div>
    );
  }
}