import { h, Component } from 'preact'
import L from 'leaflet'
// @ts-ignore
import style from "./pickupmap.scss"
import { bookStore } from '../../bookstore';
import { PickupPoint } from '../../../functions/src/shared/shared';

class PickUpMapProps {
  onSelect: (p:PickupPoint)=>void
}

export class PickUpMap extends Component<PickUpMapProps> {
  map: any
  data: any

  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.emitEvent = this.emitEvent.bind(this);

    document.getElementsByTagName("head")[0].insertAdjacentHTML("beforeend",`<link rel="stylesheet" href="https://unpkg.com/leaflet@1.4.0/dist/leaflet.css"
      integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=="
      crossorigin=""/>`
    );
  }

  componentWillUnmount() {
    if(this.map != null)
      this.map.remove();
  }

  componentDidMount() {
    let zipcode = bookStore.order.customer.zipcode;
    let address = bookStore.order.customer.address;

    fetch(`/api/pakkeshops?zipcode=${zipcode}&address=${address}`)
      .then(res => res.json())
      .then(res => {
        this.data = res
        this.initMap()
      })
      .catch(e => bookStore.notifications.addWarn("Kan ikke hente kort over pakkeshops. Prøv igen senere."));
  }

  emitEvent(pakkeshop) {
    this.props.onSelect({
      name: pakkeshop.name,
      address: pakkeshop.address.street,
      zipcode: pakkeshop.address.postal_code,
      city: pakkeshop.address.city,
      id: pakkeshop.droppoint_id
    });
  }

  initMap() {
    const lat = this.data.result[0].coordinate.latitude;
    const lon = this.data.result[0].coordinate.longitude;
    this.map = L.map("map").setView([lat, lon], 13);

    const accessToken = "pk.eyJ1IjoicGo5OGEiLCJhIjoiY2pzNGV0M3Y4MDFpbzQ1cGMzNGQxY3ozcyJ9.voFTb_QxI6SIzRCTbCSXYg";
    L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      // @ts-ignore
      id: 'mapbox.streets',
      accessToken: accessToken
    }).addTo(this.map);

    this.data.result.map((pakkeshop, i) => {
      const lat = pakkeshop.coordinate.latitude;
      const lon = pakkeshop.coordinate.longitude;
      const hours = pakkeshop.opening_hours;

      var marker = L.marker([lat, lon]).addTo(this.map);
      marker.bindPopup(`
        <b>${pakkeshop.name}</b><br>
        ${pakkeshop.address.street}<br/>
        <br/>
        <table class="${style.openingHours}">
          <tr>
            <td>Man</td>
            <td>${hours.MO.from} - ${hours.MO.to}</td>
          </tr>
          <tr>
            <td>Tir</td>
            <td>${hours.TU.from} - ${hours.TU.to}</td>
          </tr>
          <tr>
            <td>Ons</td>
            <td>${hours.WE.from} - ${hours.WE.to}</td>
          </tr>
          <tr>
            <td>Tor</td>
            <td>${hours.TH.from} - ${hours.TH.to}</td>
          </tr>
          <tr>
            <td>Fre</td>
            <td>${hours.FR.from} - ${hours.FR.to}</td>
          </tr>
          <tr>
            <td>Lør</td>
            <td>${hours.SA.from} - ${hours.SA.to}</td>
          </tr>
          <tr>
            <td>Søn</td>
            <td>${hours.SU.from} - ${hours.SU.to}</td>
          </tr>
        </table>        
        `);
      marker.on('click', () => this.emitEvent(pakkeshop));

      if(i == 0) {
        marker.openPopup();
        this.emitEvent(pakkeshop);
      }
    })
  }

  render() {
    return (
      <div id="map" class={style.map}>
      </div>
    );
  }
}