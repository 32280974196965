import { h, Component } from "preact";
import { bookStore } from "../../bookstore";
// @ts-ignore
import style from "./subscribe.scss";
// @ts-ignore
import bo from "~assets/images/bienbo/bo.png";

function closeModal() {
  bookStore.modal.close();
}

function TakModal() {
  return (
    <div class={style.takModal}>
      <h1>Tak for din interesse</h1>
      <p>Vi har tilmeldt dig vores nyhedsbrev, så du vil blive opdateret når der sker noget nyt her på siden</p>
      <img src={bo} />
      <div class={style.lukButton} onClick={closeModal}>Luk</div>
    </div>
  );
}

class SubscribeState {
  email: string
}

export class Subscribe extends Component<{}, SubscribeState> {
  constructor() {
    super();
    this.onInput = this.onInput.bind(this);
    this.onSubscribe = this.onSubscribe.bind(this);

    this.state = {email: ""}
  }

  onSubscribe() {
    if(!this.state.email.includes("@")) return

    fetch(`/api/subscribe?email=${this.state.email}`);
    bookStore.modal.open(<TakModal />);
    this.setState({email: ""});
  }

  onInput(e) {
    this.setState({email: e.target.value});
  }

  render({}, {email}) {
    return (
    <div class={style.container}>
      <h2 class={style.title}>Modtag vores nyhedsbrev</h2>
      <input type="email" class={style.email} placeholder="Email adresse" value={email} onInput={this.onInput} />
      <div class={style.button} onClick={this.onSubscribe}>
        <div class={style.tilmeld}>Tilmeld</div>
        <div class={style.arrow}></div>
      </div>
    </div>
    );
  }
}