import { h, Component } from "preact"
import { bookStore } from "../../bookstore";
// @ts-ignore
import style from "./itemtable.scss"
import { Link } from "../link/link";
import { OrderTotal, Format, } from "../../../functions/src/shared/shared";

class ItemTableProps {
  onRemove?: (i:number)=>void
  linkItems: boolean
}

export class ItemTable extends Component<ItemTableProps> {
  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }

  onItemRemove(idx:number) {
    this.props.onRemove(idx);
    this.setState({});
  }

  renderRow(id, idx) {
    const prod = bookStore.shared.getProduct(id);
    const info = bookStore.getProductInfo(id);
    let formatStr = "";
    if(prod.format == Format.ebook) formatStr = "e-bog";
    else formatStr = "paperback";

    const item = (
      <div>
        <img src={info.image} class={style.itemImage} alt="Bog" />
        <div class={style.title}>
          <h3 class={style.itemTitle}>{info.title}</h3>
          <h5 class={style.itemSubtitle}>{info.subtitle}</h5>
          <h5 class={style.itemFormat}>[{formatStr}]</h5>
        </div>
      </div>
    );

    return (
      <tr>
        <td>
        {
          this.props.linkItems ?
          <Link url={info.url}>{item}</Link>
          : item
        }          
        </td>
        <td>
          {
          this.props.onRemove &&
          <div class={style.trash} onClick={()=>{this.onItemRemove(idx)}}/>
          }
          {prod.price.toString()}
        </td>
      </tr>
    );
  }

  render() {
    const items = bookStore.order.items;
    const total = bookStore.order.calculateOrderTotal();

    return (
    <table class={style.table}>
      { items.map((id, idx) => this.renderRow(id, idx)) }
      <tr><td colSpan={2}><hr /></td></tr>
      {
        total.discount.value < 0 &&
        <tr class={style.discount}>
          <td>Rabat</td><td>{total.discount.toString()}</td>
        </tr>
      }
      <tr>
        <td>Samlet</td>
        <td>
          {total.discount.value < 0 &&
          <div class={style.samletFoer}>{total.subtotal.toString()}</div>
          }
          <div class={style.samletEfter}>{total.discountedSubtotal.toString()}</div>
        </td>
      </tr>
      {
        items.length>0 &&
        <tr><td colSpan={2}><hr /></td></tr>
      }
      <tr>
        <td>Levering</td>
        <td>{total.shipping.toString()}</td>
      </tr>
      {
        items.length>0 &&
        <tr>
          <td><strong>I alt</strong></td>
          <td><strong>{total.total.toString()}</strong></td>
        </tr>
      }            
    </table>
    );
  }
}