import { h, Component } from "preact"
import { bookStore } from "../../bookstore"
import { Link } from "../link/link"
// @ts-ignore
import style from "./mobilemenu.scss"

export class MobileMenu extends Component {
  render() {
    const menuStyle = bookStore.mobileMenu.show ? "" : "pointer-events:none";
    const overlayStyle = bookStore.mobileMenu.show ? "opacity:1;" : "opacity:0;";
    const panelStyle = bookStore.mobileMenu.show ? "left:0;" : "left: -80%;";

    return (
      <aside class={style.mobileMenu} style={menuStyle}>
          <nav class={style.mobileMenuPanel} style={panelStyle}>
            <Link url="/">Bøger</Link>
            {
              bookStore.productInfo.map(i => 
                <Link url={i.url}><div class={style.submenu}>{i.title}</div></Link>
              )
            }
            <Link url="/om-os"><div>Om os</div></Link>
            <Link url="/faq"><div class={style.submenu}>Spørgsmål og svar</div></Link>
            <Link url="/betingelser"><div class={style.submenu}>Handelsbetingelser</div></Link>
          </nav>
          <div class={style.mobileMenuOverlay} style={overlayStyle} onClick={bookStore.mobileMenu.close}></div>
      </aside>
    );
  }
}