import * as shared from "../functions/src/shared/shared";
import { Order } from "../functions/lib/shared/shared";
type Item = shared.Item;

class Cart {
  items: Item[]

  init() {
    const lc = window.localStorage.getItem("cart");
    if(lc == null)  this.items = [];
    else            this.items = JSON.parse(lc);
    this.update();
  }

  update() {
    window.localStorage.setItem("cart", JSON.stringify(this.items));
    bookStore.order.items = this.items.slice(0);
    bookStore.update();
  }

  add(item:Item) {
    this.items.push(item);
    this.update();
  }

  remove(i:number) {
    this.items.splice(i, 1);
    this.update();
  }

  clear() {
    this.items = [];
    // Update application (without updating {order})
    window.localStorage.setItem("cart", JSON.stringify(this.items));
    bookStore.update();
  }
}

class Modal {
  element: any
  show: boolean
  blocking: boolean

  open(el:any, blocking:boolean = false) {
    this.element = el;
    this.show = true;
    this.blocking = blocking;
    bookStore.update();
  }

  close() {
    this.show = false;
    bookStore.update();
  }
}

class MobileMenu {
  show: boolean 
  open() {
    this.show = true;
    bookStore.update();
  }
  close() {
    this.show = false;
    bookStore.update();
  }
}

export class Notification {
  level: string
  element: any
  action: ()=>void

  constructor(level:string, element:any, action:()=>void = null) {
    this.level = level;
    this.element = element;
    this.action = action;
  }
}

class Notifications {
  list: Notification[] = []

  addInfo(el:any, action:()=>void = null) {
    const n = new Notification("info", el, action);
    this.list.push(n);
    bookStore.update();
    setTimeout(() => this.close(n), 5000);
  }

  addWarn(el:any, action:()=>void = null, timeout:boolean = false) {
    const n = new Notification("warning", el, action);
    this.list.push(n);
    bookStore.update();
    if(timeout) {
      setTimeout(() => this.close(n), 5000);
    }
  }

  close(n:Notification) {
    let idx = -1;
    for(let i = 0; i < this.list.length; i++) {
      if(this.list[i] === n) {
        idx = i;
        break;
      }
    }

    if(idx == -1) return;
    this.list.splice(idx, 1);
    bookStore.update();
  }
}

export class BookInfo {
  ids: {
    book: Item
    ebook:Item
  }
  title: string
  subtitle: string
  url: string
  description: string
  age: string
  pages: number
  image: string
  background: string
  samples: string[]
}

const bienBoInfo: BookInfo = {
  ids: {
    book: "bien_bo",
    ebook: "bien_bo.ebook"
  },
  title: "Bien Bo",
  subtitle: "og den blå blomst",
  url: "/bien-bo",
  description: "Bien Bo er historien om Bo som finder den smukkeste blomst på hele marken. Men det er ikke altid nemt at få andre til at tro på én.",
  age: "2-6",
  pages: 32,
  image: require("~assets/images/bienbo/cover.jpg"),
  background: require("~assets/images/bienbo/bg.jpg"),
  samples: [
    require("~assets/images/bienbo/sample1.jpg"),
    require("~assets/images/bienbo/sample2.jpg"),
    require("~assets/images/bienbo/sample3.jpg")
  ]
}
const krokodillenKarlInfo: BookInfo = {
  ids: {
      book: "krokodillen_karl",
      ebook: "krokodillen_karl.ebook"
  },
  title: "Krokodillen Karl",
  subtitle: "der ville klatre i træer",
  url: "/krokodillen-karl",
  description: "Krokodillen Karl er historien om Karl som kommer til at kaste sin bold op i et træ. Hvordan skal han nogensinde få den igen? Krokodiller kan jo ikke klatre i tæer. Eller kan de?",
  age: "2-6",
  pages: 32,
  image: require("~assets/images/krokodillenkarl/cover.jpg"),
  background: require("~assets/images/krokodillenkarl/bg.jpg"),
  samples: [
    require("~assets/images/krokodillenkarl/sample1.jpg"),
    require("~assets/images/krokodillenkarl/sample2.jpg"),
    require("~assets/images/krokodillenkarl/sample3.jpg")
  ]
}
const fiskenFrejaInfo: BookInfo = {
  ids: {
    book: 'fisken_freja',
    ebook: 'fisken_freja.ebook'
  },
  title: 'Fisken Freja',
  subtitle: 'og det gamle skattekort',
  url: '/fisken-freja',
  description: 'Fisken Freja er historien om Freja, der finder et gammelt skattekort. Hvor mon det leder hen? Freja er ivrig efter at finde svaret, men hun møder noget uventet på hendes rejse.',
  age: '2-6',
  pages: 32,
  image: require('~assets/images/fiskenfreja/cover.jpg'),
  background: require('~assets/images/fiskenfreja/bg.jpg'),
  samples: [
    require("~assets/images/fiskenfreja/sample1.jpg"),
    require("~assets/images/fiskenfreja/sample2.jpg"),
    require("~assets/images/fiskenfreja/sample3.jpg")
  ]
}

export const bookStore = {
  rootEl: null,
  shared,
  order: new Order(),
  cart: new Cart(),
  modal: new Modal(),
  termsAccepted: false,
  notifications: new Notifications(),
  mobileMenu: new MobileMenu(),
  productInfo: [
    bienBoInfo,
    krokodillenKarlInfo,
    fiskenFrejaInfo
  ],
  getProductInfo(id:string) {
    return bookStore.productInfo.find(i => i.ids.book == id || i.ids.ebook == id);
  },

  saveCustomer() {
    window.localStorage.setItem("customer", JSON.stringify(bookStore.order.customer));
  },

  // initializes the bookstore (must be called a app init)
  init(root) {
    bookStore.rootEl = root
    bookStore.cart.init()

    try {
      const customerStr = window.localStorage.getItem("customer");
      const customer = JSON.parse(customerStr);
      Object.assign(bookStore.order.customer, customer);
    }
    catch { }
  },
  // updates the entire virtual DOM
  update() {
    bookStore.rootEl.setState({})
  },
  // goes to a single-page-app location
  link(url:string) {
    document.documentElement.scrollTop = 0
    window.history.pushState(null, null, url)
    bookStore.mobileMenu.close()
    bookStore.update()
  }
}