import { h } from 'preact';
import { bookStore, Notification } from '../../bookstore';
// @ts-ignore
import style from "./notifications.scss";

function close(n:Notification) {
  if(n.action != null)
    n.action();
  bookStore.notifications.close(n);
}

export function Notifications() { 
  return (
    <div class={style.notifications}>
      {
        bookStore.notifications.list.map(n => 
          <div class={style.box + " " + style[n.level]} onClick={()=>close(n)}>
            {n.element}
          </div>
        )
      }
    </div>
  );
}