import { h, Component } from "preact";
import { bookStore } from "../../bookstore";
// @ts-ignore
import style from "./thanks.scss";
import { ItemTable } from "../../components/itemtable/itemtable";
import { Order } from "../../../functions/src/shared/shared";

export default class Thanks extends Component {
  order: Order

  constructor() {
    super();
    this.order = bookStore.order;

    if(this.order.id == -1)
      bookStore.link("/");
  }

  render() {
    return (
    <div class={style.thanks}>
      <h1>Tak for din bestilling</h1>
      <p>Vi har modtaget din ordre, som har fået ordrenr. <strong>{this.order.id}</strong>.
      <br />Vi har sendt en ordrebekræftelse til din e-mail: <strong>{this.order.customer.email}</strong></p>
      <h2>Din ordre</h2>
      <ItemTable linkItems={false} />
    </div>
    )
  }
}