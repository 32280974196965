import { h, Component } from "preact";
// @ts-ignore
import style from "./terms.scss";


export default class Terms extends Component {
  render() {
    return (
    <article class={style.terms}>
      <h1>Handelsbetingelser</h1>

      <section>
        <h3>Generelle oplysninger</h3>
        <p>
          Little Stories<br />
          Rebslagergade 14, 1.th<br />
          6400 Sønderborg<br />
          CVR nr.: 40106960<br />
          Email: patrick.littlestories@gmail.com
        </p>
      </section>
      <section>
        <h3>Priser</h3>
        <p>
          Hos Little Stories tager vi forbehold for eventuelle fejl i vores angivne priser. Endvidere forbeholder vi os ret til at ændre i priserne uden forudgående samtykke. Der tages forbehold for udsolgte varer.
        </p>
      </section>
      <section>
        <h3>Betaling</h3>
        <p>
          Alle beløb er i DKK. Danske kroner og er incl. moms.
        </p>
        <p>
          Little Stories modtager betaling med Visa, Mastercard, American Express, Discover, Diners Club og JCB. Betalingen vil først blive trukket på din konto, når varen afsendes. Alle beløb er i DKK. Danske kroner. Der tages forbehold for prisfejl og udsolgte/udgåede varer.
        </p>
        <p>
          Little Stories bruger en sikker betalingsservice (<a href="https://stripe.com/">Stripe</a>), der krypterer alle dine kortoplysninger med SSL (Secure Socket Layer) protokol. Det betyder, at man ikke kan aflæse dine informationer.
        </p>
      </section>
      <section>
        <h3>Levering</h3>
        <p>
        Little Stories tilstræber at afsende ordrer indgået inden kl. 17 samme dag, ordrer herefter sendes næstfølgende hverdag. 
        Vi sender til pakkeshops i hele Danmark. Fragtpriser fra 39 kr. Varer vil blive leveret til pakkeshoppen, der angives ved bestillingen.
        <br />
        Vi leverer ikke til udlandet og ikke til Færøerne og Grønland.
        <br />
        Dine varer sendes med <a href="http://www.dao.as/">DAO365</a>.
        <br />
        Der leveres varer alle ugens dage. Din vare vil blive leveret 3-5 hverdage efter bestillingen.
        </p>
      </section>
      <section>
        <h3>Reklamationsret</h3>
        <p>
          Der gives 2 års reklamationsret i henhold til købeloven. Vores reklamationsret gælder for fejl i materiale og/eller fabrikation. Du kan få varen ombyttet, pengene retur eller afslag i prisen, afhængig af den konkrete situation. Reklamationen gælder ikke fejl eller skader begået ved forkert håndtering af produktet. Du skal reklamere i "rimelig tid" efter du har opdaget manglen/fejlen. Little Stories vil dække returneringsomkostninger i et rimeligt omfang.
        </p>
        <p>
          Ved returnering, reklamationer og benyttelse af fortrydelseretten sendes til: 
        </p>
        <p>
          Little Stories<br />
          Rebslagergade 14, 1.th<br />
          6400 Sønderborg
        </p>
        <p>
          Der modtages ikke forsendelser pr. efterkrav.
        </p>
      </section>
      <section>
        <h3>Refusion</h3>
        <p>
          Hvis der er tale om refusion, bedes du medsende bankoplysninger i form af regnr. og kontonr., så det aftalte beløb kan overføres. Disse oplysninger kan uden risiko oplyses pr. mail eller anden elektronisk form, da det ikke er følsomme oplysninger og kun vil blive anvendt til vores opfyldelse af refusionen.
        </p>
      </section>
      <section>
        <h3>Fortrydelsesret</h3>
        <p>
          Der gives 14 dages fuld returret på varer købt i vores Webshop.
          Perioden regnes fra den dag, hvor du modtager ordren.
        </p>
        <p>
          Returneringsomkostninger skal du selv afholde.
        </p>
        <p>
          Fortrydelse skal anmeldes til os senest 14 dage efter købet og fra fortrydelsen skal du senest 14 dage efter returnere forsendelsen.
        </p>
        <p>
          Meddelelsen skal gives pr. mail på <a href="mailto:patrick.littlestories@gmail.com">patrick.littlestories@gmail.com</a>. 
          I meddelelsen skal du gøre os tydeligt opmærksom på, at du ønsker at udnytte din fortrydelsesret.
        </p>
        <p>
          Ved returnering, reklamationer og benyttelse af fortrydelseretten sendes til: 
        </p>
        <p>
          Little Stories<br />
          Rebslagergade 14, 1.th<br />
          6400 Sønderborg
        </p>
        <p>
          Varens stand, når du sender den retur<br />
          Du hæfter kun for eventuel forringelse af varens værdi, som skyldes anden håndtering, end hvad der er nødvendigt for at fastslå varens art, egenskaber og den måde, den fungerer på. Med andre ord – du kan prøve varen på samme måde, som hvis du prøvede den i en fysisk butik.
        </p>
        <p>
          Hvis varen er prøvet udover, hvad der er beskrevet ovenfor, betragter vi den som brugt, hvilket betyder, at du ved fortrydelse af købet kun får en del eller intet af købsbeløbet retur, afhængig af varens handelsmæssige værdi.
          <br />
          For at modtage hele købsbeløbet retur må du altså gøre det samme, som man kan i en fysisk butik. Du må afprøve varen, men ikke tage den i egentlig brug.
        </p>
      </section>
      <section>
        <h3>Klagemuligheder – oversigt og links:</h3>
        <p>
          Har du en klage over et produkt, købt i vores Webshop, kan der sendes en klage til:
        </p>
        <p>
          Konkurrence- og Forbrugerstyrelsens Center for Klageløsning<br />
          Carl Jacobsens Vej 35<br />
          2500 Valby<br />
          Link: <a href="https://www.forbrug.dk">www.forbrug.dk</a>
        </p>
        <p>
          Hvis du er forbruger med bopæl i et andet EU-land, kan du angive din klage i EU Kommissionens online klageplatform.<br />
          Platformen findes her: <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a><br/>
          Angiver du en klage her, skal du oplyse vores E-mail adresse: patrick.littlestories@gmail.com
        </p>
      </section>
    </article>);
    }
}