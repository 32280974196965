import {h, Component} from 'preact';
import { bookStore } from '../../bookstore';
import { Spinner } from '../spinner/spinner';
// @ts-ignore
import style from "./payment.scss";
// @ts-ignore
import logo from "~assets/images/logo.png";
//@ts-ignore
import stripeLogo from "~assets/images/powered_by_stripe.svg"

const test_pk = 'pk_test_hHl61Z6kgIo0YoKfrAaWLJWg';
const prod_pk = 'pk_live_2Ffidmb1OvkNJr2CuTkRegVT';
const stripeKey = (window.location.host == "www.littlestories.dk") ? prod_pk : test_pk;

export class Payment extends Component {
  onClick() {
    bookStore.modal.open(<PaymentModal key={Date.now()} />, true);
  }
  
  render() {
    return (
      <div>
        <div onClick={this.onClick} class={style.button}>
          <div>Gå til betaling</div>
        </div>
      </div>
    );
  }
}

class PaymentModalState {
  canCharge:boolean
}

class PaymentModal extends Component<{}, PaymentModalState> {
  card: any
  stripe: any
  clientSecret: string = ''

  constructor() {
    super()

    this.performPayment = this.performPayment.bind(this)
    this.state = { canCharge: false }
  }
  
  async componentDidMount() {
    await this.createCharge()

    // @ts-ignore
    this.stripe = Stripe(stripeKey);
    var elements = this.stripe.elements({locale:'da'});
    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    this.card = elements.create('card', {style: style});
    this.card.mount('#stripe');

    // Handle real-time validation errors from the card Element.
    this.card.addEventListener('change', (event) => {
      var displayError = this.base.getElementsByClassName('stripe-errors')[0];
      if (event.error)
        displayError.textContent = event.error.message;
      else
        displayError.textContent = '';
    });

    this.setState({canCharge: true})
  }

  async performPayment() {
    this.setState({canCharge: false})

    // @ts-ignore
    const {paymentIntent, error} = await this.stripe.handleCardPayment(
      this.clientSecret, this.card, {
        payment_method_data: {
          billing_details: {name: bookStore.order.customer.name}
        }
      }
    );
  
    if (error) {
      this.setState({canCharge: true})
      bookStore.notifications.addWarn(error.message);
    }
    else this.onSuccess(paymentIntent.id);
  }

  createCharge() {
    const order = bookStore.order;
    const price = bookStore.order.calculateOrderTotal().total;

    return fetch(`/api/createCharge`, {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({ order, price })
    })
      .then(r => {
        if(r.status == 200) return r.json();
        this.onError();
      })
      .then(r => { this.clientSecret = r.client_secret })
      .catch(e => this.onError(e));
  }

  onError(e?) {
    console.error(e)
    bookStore.modal.close();
    bookStore.notifications.addWarn("Der opstod en fejl. Betalingen er derfor ikke blevet gennemført.");
  }

  async onSuccess(intent) {
    const order = await fetch(`/api/processOrder`, {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({intent, clientSecret: this.clientSecret})
    })
      .then(r => {
        if(r.status == 200) return r.json()
        this.onError()
      })
      .catch(e => this.onError(e));

    if(order != null) {
      bookStore.modal.close();
      bookStore.cart.clear();
      Object.assign(bookStore.order, order);
      bookStore.link("/tak");
    }
  }

  abort() {
    bookStore.modal.close();
  }

  render() {
    const formStyle = this.state.canCharge ? '' : 'display:none;';
    const price = bookStore.order.calculateOrderTotal().total.toString();

    return (
      <div class={style.modal}>
        <h2>Indtast betalingsoplysninger</h2>
        <h3>I alt {price}</h3>
        <div style={formStyle}>
          <div id="stripe"/>
          <div class="stripe-errors" style="color: red;" role="alert"></div>
          <div class={style.formBottom}>
            <img class={style.stripeLogo} src={stripeLogo} />
            <button class={style.payButton} onClick={this.performPayment}>Betal</button>
            <button class={style.abortButton} onClick={this.abort}>Afbryd</button>
          </div>
        </div>
        {this.state.canCharge ? null : <Spinner />}
      </div>
    );
  }
}

