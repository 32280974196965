import { h } from "preact";
// @ts-ignore
import image_bo from "~assets/images/bienbo/sadbo.png";
// @ts-ignore
import icon_home from "~assets/images/icons/home.svg";
// @ts-ignore
import style from './notfound.scss';
import { bookStore } from "../../bookstore";

function goHome() {
  bookStore.link("/");
}

export default function NotFound() {
  return (
    <div class={style.notfound}>
      <div class={style.bg}></div>
      <div class={style.container}>
        <h1>Siden blev ikke fundet</h1>
        <img src={image_bo} />
        <div class={style.home} onClick={goHome}>
          <img src={icon_home} />
          Til forsiden
        </div>
      </div>
    </div>
  );
}