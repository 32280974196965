import {h} from 'preact'
import { Carousel } from '../../components/carousel/carousel'
import { BuyButton } from '../../components/buybutton/buybutton'
// @ts-ignore
import style from "./bookpage.scss"
import { BookInfo } from '../../bookstore';

export function BookPage({info}:{info:BookInfo}) {
  let bgstyle = `background-image: url(${info.background});`

  return (
    <div class={style.container}>
      <div class={style.bg} style={bgstyle}></div>
      <div class={style.book}>
        <div class={style.preview}>
          <Carousel info={info} />
        </div>
        <div class={style.info}>
          <article>
            <h1 class={style.title}>{info.title}</h1>
            <h2 class={style.subtitle}>{info.subtitle}</h2>
            <div class={style.age}>{info.age} år</div>
            <div class={style.description}>{info.description}</div>
          </article>
          <div>
            <div class={style.pages}>{info.pages} sider, softcover</div>
            <BuyButton info={info} />
          </div>
        </div>
      </div>
    </div>
  )
}