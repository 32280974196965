import { h, Component } from 'preact';
import { bookStore } from '../../bookstore';
import { Link } from '../../components/link/link';
// @ts-ignore
import icon_check from "~assets/images/icons/check-mark.svg";
// @ts-ignore
import style from './oplysninger.scss';
import { Customer } from '../../../functions/src/shared/shared';

class OplysningerState {
  email:  string
  name:   string
  address:string
  zipcode:string
  city:   string
  mobile: string
  termsAccepted: boolean
  
  emailOk:    boolean
  zipcodeOk:  boolean
  mobileOk:   boolean
}

export default class Oplysninger extends Component<{}, OplysningerState> {
  constructor() {
    super();
    if(bookStore.order.items.length == 0)
      bookStore.link("/");

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.onZipcodeChange = this.onZipcodeChange.bind(this);
    this.onCityChange = this.onCityChange.bind(this);
    this.onMobileChange = this.onMobileChange.bind(this);
    this.onAcceptChange = this.onAcceptChange.bind(this);
    this.canContinue = this.canContinue.bind(this);
    this.onContinue = this.onContinue.bind(this);

    this.state = {
      email:    bookStore.order.customer.email,
      name:     bookStore.order.customer.name,
      address:  bookStore.order.customer.address,
      zipcode:  bookStore.order.customer.zipcode,
      city:     bookStore.order.customer.city,
      mobile:   bookStore.order.customer.mobile,
      termsAccepted: false,
      
      emailOk:    true,
      zipcodeOk:  true,
      mobileOk:   true,
    };

    if(this.state.email.length > 0)
      this.setState({emailOk: this.validateEmail(this.state.email)});
    if(this.state.zipcode.length > 0)
      this.setState({zipcodeOk: this.validateZipcode(this.state.zipcode)})
    if(this.state.mobile.length > 0)
      this.setState({mobileOk: this.validateMobile(this.state.mobile)})
  }

  validateEmail(email) {
    return (email.match(/[^@]+@[^\.]+\..+/) != null);
  }
  validateZipcode(zip) {
    return (zip.match(/^[0-9]{4} ?$/) != null);
  }
  validateMobile(mobile) {
    return (mobile.match(/^[0-9]{8} ?$/) != null);
  }

  onEmailChange(e) {
    this.setState({email: e.target.value, emailOk: this.validateEmail(e.target.value)});
    bookStore.order.customer.email = this.state.email;
    bookStore.saveCustomer();
  }
  onNameChange(e) {
    this.setState({name: e.target.value});
    bookStore.order.customer.name = this.state.name;
    bookStore.saveCustomer();
  }
  onAddressChange(e) {
    this.setState({address: e.target.value});
    bookStore.order.customer.address = this.state.address;
    bookStore.saveCustomer();
  }
  onZipcodeChange(e) {
    this.setState({zipcode: e.target.value, zipcodeOk: this.validateZipcode(e.target.value)});
    bookStore.order.customer.zipcode = this.state.zipcode;
    bookStore.saveCustomer();
  }
  onCityChange(e) {
    this.setState({city: e.target.value});
    bookStore.order.customer.city = this.state.city;
    bookStore.saveCustomer();
  }
  onMobileChange(e) {
    this.setState({mobile: e.target.value, mobileOk: this.validateMobile(e.target.value)});
    bookStore.order.customer.mobile = this.state.mobile;
    bookStore.saveCustomer();
  }

  onAcceptChange() {
    this.setState({termsAccepted: !this.state.termsAccepted});
  }

  canContinue() {
    return !(this.state.email.length == 0 || this.state.name.length == 0 || this.state.address.length == 0 ||
             this.state.zipcode.length == 0 || this.state.city.length == 0 ||
             !this.state.termsAccepted || !this.state.emailOk || !this.state.zipcodeOk || !this.state.mobileOk);
  }
  onContinue() {
    if(!this.canContinue()) return;
    bookStore.termsAccepted = this.state.termsAccepted;
    bookStore.link("/bestilling");
  }

  render() {
    let continueStyle = this.canContinue() ? "" : "opacity: 0.5;cursor:default;";
    const errStyle = "border-color: red;";
    let emailStyle = this.state.emailOk ? "" : errStyle;
    let zipcodeStyle = this.state.zipcodeOk ? "" : errStyle;
    let mobileStyle = this.state.mobileOk ? "" : errStyle;

    return (
      <div class={style.oplysninger}>
        <h1>Dine oplysninger</h1>

        <p>
          Indtast dine oplysninger her.<br />
          Hvis du køber e-bøger vil du modtage links til at downloade dem på din e-mail.
        </p>
        <table>
          <tr>
            <td>E-mail</td>
            <td><input type="email" placeholder="E-mail" value={this.state.email} onInput={this.onEmailChange} style={emailStyle} /></td>
          </tr>
          <tr>
            <td>Navn</td>
            <td><input placeholder="Navn" value={this.state.name} onInput={this.onNameChange} /></td>
          </tr>
          <tr>
            <td>Adresse</td>
            <td><input placeholder="Adresse" value={this.state.address} onInput={this.onAddressChange} /></td>
          </tr>
          <tr>
            <td>Postnr.</td>
            <td><input placeholder="Postnr." value={this.state.zipcode} onInput={this.onZipcodeChange} style={zipcodeStyle} /></td>
          </tr>
          <tr>
            <td>By</td>
            <td><input placeholder="By" value={this.state.city} onInput={this.onCityChange} /></td>
          </tr>
          <tr>
            <td>Mobil nr.</td>
            <td><input placeholder="Mobil nr." value={this.state.mobile} onInput={this.onMobileChange} style={mobileStyle} /></td>
          </tr>
        </table>
        <div class={style.accept}>
          <span class={style.check} onClick={this.onAcceptChange}>
            {this.state.termsAccepted &&
              <img src={icon_check} class={style.checkmark} />
            }
          </span>
          <span>Jeg accepterer <Link url="/betingelser">
              <span class={style.link}>handelsbetingelserne</span>
            </Link>.
          </span>
        </div>

        <div class={style.continue} style={continueStyle} onClick={this.onContinue}>
          Fortsæt
        </div>
      </div>
    );
  }
}