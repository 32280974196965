import { h, Component } from "preact";
import { bookStore } from "../../bookstore";
// @ts-ignore
import style from "./link.scss";

class LinkProps {
  url: string
}

export class Link extends Component<LinkProps> {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    bookStore.link(this.props.url);
    e.preventDefault();
    e.stopPropagation();
  }

  render({url, children}) {
    return <a href={url} onClick={this.onClick} class={style.link}>{children}</a>;
  }
}