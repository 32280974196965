import { h, Component } from "preact";
import { bookStore } from "../../bookstore";
// @ts-ignore
import style from "./modal.scss";


export class Modal extends Component {
  onClose() {
    if(!bookStore.modal.blocking)
      bookStore.modal.close();
  }

  onModalClick(e) {
    e.stopPropagation();
  }

  render() {
    const modalStyle = bookStore.modal.show ? "opacity:1;" : "pointer-events:none;opacity:0;" ;

    return (
      <div class={style.overlay} onClick={this.onClose} style={modalStyle}>
        <div class={style.modal} onClick={this.onModalClick}>       
         { !bookStore.modal.blocking &&
          <div class={style.close} onClick={this.onClose}></div> }
         {bookStore.modal.element}
        </div>
      </div>
    );
  }
}