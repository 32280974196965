import {h, Component} from 'preact';
import { bookStore } from '../../bookstore';
// @ts-ignore
import style from "./header.scss";
// @ts-ignore
import image_logo from "~/assets/images/logo.png";
// @ts-ignore
import image_logoText from "~/assets/images/logo-text.png";
// @ts-ignore
import icon_menu from "~/assets/images/icons/menu.svg";
// @ts-ignore
import icon_home from "~/assets/images/icons/home.svg";
// @ts-ignore
import icon_cart from "~/assets/images/icons/cart.svg";
import { Link } from '../link/link';

export class Header extends Component {
  renderItemsInCart() {
    const numItems = bookStore.cart.items.length;
    if(numItems > 0)
      return <div class={style.itemInCart}>{numItems}</div>;
  }

  openMobileMenu() {
    bookStore.mobileMenu.open();
  }

  menuItemClass(path) {
    let cls = style.menuItem;
    if(window.location.pathname == path)
      cls += ` ${style.current}`;
    return cls;
  }

  render() {
    return (
      <header id="header" class={style.header}>
        <div class={style.topPart}>
          <div>
            <div class={style.logoContainer}>
              <Link url="/">
                <img src={image_logo} class={style.logo} alt="Hjem" />
                <img src={image_logoText} class={style.logoText} alt="Hjem" />
              </Link>
            </div>
            <div class={style.buttons}>
              <div class={style.menuButton}>
                <div class={style.menuButtonContent} onClick={this.openMobileMenu}>
                  Menu
                  <img src={icon_menu} alt="Menu" />
                </div>
              </div>
                <div class={style.cart}>
                  <Link url="/kurv">
                    <div class={style.cartContent}>
                        Din kurv
                        {this.renderItemsInCart()}
                        <img src={icon_cart} alt="Kurv" />
                    </div>
                  </Link>
                </div>
              <div class={style.cartShadow}></div>
            </div>
          </div>
        </div>
        <div class={style.menu}>
          <nav>
            <div class={style.menuItem}>
              <Link url="/"><img class={style.home} src={icon_home}/></Link>
            </div>
            <div class={this.menuItemClass("/bien-bo")}>
              <Link url="/bien-bo">Bien Bo</Link>
            </div>
            <div class={this.menuItemClass("/krokodillen-karl")}>
              <Link url="/krokodillen-karl">Krokodillen Karl</Link>
            </div>
            <div class={this.menuItemClass("/fisken-freja")}>
              <Link url="/fisken-freja">Fisken Freja</Link>
            </div>
            <div class={style.omOs + " " + this.menuItemClass("/om-os")}>
              <Link url="/om-os">Om os</Link>

              <div class={style.omOsMenu}>
                <Link url="/om-os">Velkommen</Link>
                <Link url="/faq">Spørgsmål og svar</Link>
                <Link url="/betingelser">Handelsbetingelser</Link>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

