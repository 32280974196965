import { h, Component } from 'preact';
// @ts-ignore
import style from "./omos.scss";
// @ts-ignore
import image_logo from "~assets/images/logo.png";
// @ts-ignore
import image_drawing from "~assets/images/characters/drawing.png";
import { Link } from '../../components/link/link';

export default class OmOs extends Component {
  render() {
    return (
      <article class={style.omos}>
        <h1>Om os</h1>
        <section>
          <div class={style.logo}>
            <img src={image_logo} alt="logo" />
          </div>
          <p><strong>Hej og rigtig hjertelig velkommen til Little Stories</strong></p>
          <p>
            Little Stories er en virksomhed i Sønderjylland, som blev til i 2018 med det formål at bringe 
            unikke historier og farverige fortællinger til børn, såvel som til voksne.
          </p>
          <p>
            Vi har hos Little Stories et stort ønske om, at vores bøger kan være med til at fremme den hyggelig stund, 
            som opstår mellem børn og forældre, bedsteforældre eller søskende, når man samles om eksempelvis godnatlæsningen.
          </p>
          <p>
            Nærvær, kreativitet og tid er kerneværdierne i vores virksomhed. Det er vigtigt at have tid til 
            at være sammen med dem man holder af. Tid til at drosle lidt ned fra hverdagens stress og jag. 
            Og tid til at opleve det nærvær, som opstår når man læser en god bog. 
          </p>
          <p>
            Hos Little Stories elsker vi at beskæftige os med, samt udfordre kreativiteten. Vi beundrer den proces, 
            som følger med den gode historie lige fra ide til færdigt produkt. Vi glæder os over, at vi hver dag får 
            lov til at arbejde og videreføre den kreativitet, som man så særligt og livsbekræftende oplever som barn. 
          </p>
          <p>
            Kreativiteten stopper ikke, når vores bøger er skrevet og tegnet færdigt. Den starter først rigtig, 
            når bøgerne kommer ud til små og store sjæle, som med nysgerrighed tænker over historierne og digter videre på dem. 
          </p>
          <div class={style.drawing}>
            <img src={image_drawing} alt="logo" />
          </div>
          <p>
            Den kreativitet kan du sikkert nikke genkendende til. For hvem har ikke som barn tænkt over, hvordan det ville 
            være hvis man kunne flyve? Og hvem har ikke altid haft lyst til at vise verden, at man kan meget mere end 
            den tror? Og ti gange så meget som sin mor tror!
          </p>

          <p>
            Historierne som I kan læse hos Little Stories bringer et budskab til børn om, at man kan hvad man vil, at det er 
            okay at være hvile i sig selv, og at man altid skal forfølge sine drømme. De leder op til den gode samtale, 
            som man efterfølgende kan have med sit barn - også i en ung alder. 
          </p>
          <p>
            Op netop det med, at man skal forfølge sine drømme, har været en grundsten for, at Little Stories blev til. 
            Vi har valgt at forfølge vores drøm, om at skabe tid til nærvær og kreativitet særlig blandt børn og deres familier.
          </p>
          <p>
            Vi vil derfor gerne sige mange tak, fordi du har valgt at besøge vores hjemmeside. Du er med til at gøre vores drøm til en realitet. 
          </p>
          <p>Farverige hilsner</p>
          <p><strong>Patrick Jessen<br />Little Stories</strong></p>
        </section>
      </article>
    );
  }
}