import { h, Component } from 'preact';
// @ts-ignore
import icon_download from "~assets/images/icons/download.svg";
// @ts-ignore
import style from "./download.scss";
import { bookStore, BookInfo } from '../../bookstore';

function getQuery() {
  let queryStrs = window.location.search.substring(1).split("&");
  let out = {
    id: "",
    key: ""
  };

  for(let i = 0; i < queryStrs.length; i++) {
    let q = queryStrs[i].split("=");
    out[q[0]] = q[1];
  }
  return out;
}


export default class Download extends Component {
  id: string
  key: string
  info: BookInfo

  constructor() {
    super();
    this.downloadPDF = this.downloadPDF.bind(this);
    this.downloadEPUB = this.downloadEPUB.bind(this);

    let query = getQuery();
    this.id = query.id;
    this.key = query.key;
    this.info = bookStore.getProductInfo(this.id);
  }

  downloadPDF() {
    window.open(`/api/ebook?id=${this.id}&format=pdf&key=${this.key}`);
  }

  downloadEPUB() {
    window.open(`/api/ebook?id=${this.id}&format=epub&key=${this.key}`);
  }

  render() {
    if(this.info == null) {
      return (
        <div class={style.download}>
        <h1>Hent din e-bog</h1>
          Der er sket en fejl. Sikr dig at du bruger det link som du har modtaget per mail.
          Hvis du har brug for hjælp kan du kontakte os på <a href="mailto:patrick.littlestories@gmail.com">patrick.littlestories@gmail.com</a>.
        </div>
      );
    }

    return (
      <div class={style.download}>
        <h1>Hent din e-bog</h1>
        <div class={style.container}>
          <div class={style.cover}>
            <img src={this.info.image} />
          </div>
          <div class={style.info}>
            <h2>{this.info.title}</h2>
            <h3>{this.info.subtitle}</h3>
            <p>
              Her kan du hente din e-bog. Den findes i to formater, PDF og EPUB.<br/><br/>
              Hvilket format du skal vælge afhænger af hvilken enhed du bruger.<br/><br/>
              Hvis du er i tvivl kan du vælge PDF, da den kan bruges næsten alle steder.
            </p>
            <div class={style.buttons}>
              <div onClick={this.downloadPDF}>
                <img src={icon_download} />
                Hent PDF
              </div>
              <div onClick={this.downloadEPUB}>
                <img src={icon_download} />
                Hent EPUB
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}