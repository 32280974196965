import { h, Component } from "preact"
import { bookStore, BookInfo } from "../../bookstore"
// @ts-ignore
import style from "./buybutton.scss"
// @ts-ignore
import icon_cart from "~assets/images/icons/cart.svg"
import { Product } from "../../../functions/src/shared/shared";

class BuyButtonProps {
  info: BookInfo
}

export class BuyButton extends Component<BuyButtonProps> {
  bookProd: Product
  ebookProd: Product

  constructor(props) {
    super(props)

    this.bookProd = bookStore.shared.getProduct(props.info.ids.book);
    this.ebookProd = bookStore.shared.getProduct(props.info.ids.ebook);

    this.onBuyBook = this.onBuyBook.bind(this)
    this.onBuyEBook = this.onBuyEBook.bind(this)
  }

  onBuyBook() {
    bookStore.notifications.addInfo("Varen er tilføjet til kurven");
    bookStore.cart.add(this.props.info.ids.book);
  }
  onBuyEBook() {
    bookStore.notifications.addInfo("Varen er tilføjet til kurven");
    bookStore.cart.add(this.props.info.ids.ebook);
  }

  render() {
    return (
      <div class={style.buyOptions}>
        {
          this.bookProd &&
          <div class={style.buyOption} onClick={this.onBuyBook}>
            <div class={style.buyOptionType}>Bog</div>
            <div class={style.buyOptionButton}>
              {this.bookProd.price.toString()}
              <img src={icon_cart} alt="Køb bog" />
            </div>
          </div>
        }
        {
          this.ebookProd &&
          <div class={style.buyOption} onClick={this.onBuyEBook}>
            <div class={style.buyOptionType}>E-Bog</div>
            <div class={style.buyOptionButton}>
              {this.ebookProd.price.toString()}
              <img src={icon_cart} alt="Køb e-bog" />
            </div>
          </div>
        }
      </div>
    )
  }
}