// Polyfills
import "babel-polyfill"
import 'whatwg-fetch'

// Make sure resources are imported
import "~assets/images/icons/arrow.svg"

// Core imports
import {bookStore} from "./bookstore"
import { h, render, Component } from 'preact'

// Components
import { Modal } from "./components/modal/modal"
import { Header } from "./components/header/header"
import { Footer } from "./components/footer/footer"
import { PageView } from "./components/pageview/pageview"
import { MobileMenu } from "./components/mobilemenu/mobilemenu"
import { Notifications } from "./components/notifications/notifications"

// @ts-ignore
import style from "./app.scss"

// Lazy-loaded pages
const pages = {
  Overview:         require("./pages/overview/overview"), // base route is not lazy loaded
  BienBo:           import("./pages/bienbo/bienbo"),
  KrokodillenKarl:  import("./pages/krokodillenkarl/krokodillenkarl"),
  FiskenFreja:      import("./pages/fiskenfreja/fiskenfreja"),
  OmOs:             import("./pages/omos/omos"),
  FAQ:              import("./pages/omos/faq"),
  Cart:             import("./pages/cart/cart"),
  Oplysninger:      import("./pages/oplysninger/oplysninger"),
  Bestilling:       import("./pages/bestilling/bestilling"),
  Thanks:           import("./pages/thanks/thanks"),
  Terms:            import("./pages/terms/terms"),
  Download:         import("./pages/download/download"),
  NotFound:         import("./pages/notfound/notfound")
}

class App extends Component {
  constructor() {
    super()
    bookStore.init(this)
    window.addEventListener("popstate", () => this.setState({}))
  }

  getPage() {
    switch(window.location.pathname) {
      case "/":                 return pages.Overview
      case "/tak":              return pages.Thanks
      case "/kurv":             return pages.Cart
      case "/om-os":            return pages.OmOs
      case "/faq":              return pages.FAQ
      case "/bien-bo":          return pages.BienBo
      case "/download":         return pages.Download
      case "/bestilling":       return pages.Bestilling
      case "/oplysninger":      return pages.Oplysninger
      case "/betingelser":      return pages.Terms
      case "/fisken-freja":     return pages.FiskenFreja
      case "/krokodillen-karl": return pages.KrokodillenKarl
      default:                  return pages.NotFound
    }
  }

  render() {
    // Disable scroll when modal or mobile-menu is open
    if(bookStore.modal.show || bookStore.mobileMenu.show)
      document.getElementsByTagName("html")[0].style.cssText = "overflow:hidden;";
    else 
      document.getElementsByTagName("html")[0].style.cssText = "";

    return (
      <div id="container" class={style.container}>
        <Header />
        <PageView page={this.getPage()} />
        <Footer />
        <Modal />
        <Notifications />
        <MobileMenu /> 
      </div>
    )
  }
}

render(<App />, document.body)

