import {h, Component} from 'preact'
// @ts-ignore
import style from "./carousel.scss"
// @ts-ignore
import icon_arrow from "~assets/images/icons/arrow.svg"
import { BookInfo } from '../../bookstore';

class CarouselProps {
  info: BookInfo
}
class CarouselState {
  page: number
  pageTop: number
  direction: number
  animate: boolean
}

export class Carousel extends Component<CarouselProps, CarouselState> {
  touchX: number
  touchY: number
  touching: boolean
  images: string[]

  constructor(props:CarouselProps) {
    super(props);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onImageLoad = this.onImageLoad.bind(this);

    this.goTo = this.goTo.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.prevImage = this.prevImage.bind(this);

    document.addEventListener("touchmove", this.onTouchMove);
    document.addEventListener("touchend", this.onTouchEnd);

    this.touchX = 0;
    this.touchY = 0;
    this.touching = false;

    this.state = {
      page: 0,
      pageTop: 0,
      direction: 0,
      animate: false,
    };

    this.images = [props.info.image, ...props.info.samples]
  }

  onTouchStart(e) {
    var firstTouch = e.touches[0];
    this.touchX = firstTouch.clientX;                                      
    this.touchY = firstTouch.clientY;
    this.touching = true;
  }
  onTouchEnd(e) {
    this.touching = false;
  }
  onTouchMove(e) {
    if(!this.touching) return;

    var firstTouch = e.touches[0];
    var dX = firstTouch.clientX - this.touchX;
    var dY = firstTouch.clientY - this.touchY;

    if(Math.abs(dX) > Math.abs(dY)) {
      // Swiped
      var page = this.state.page;

      if(dX < 0) page++;
      else page--;

      this.goTo(page);
      this.touching = false;
    }
    else
      this.touching = false;
  }

  goTo(idx) {
    let direction = 0;
    if(idx < this.state.pageTop) direction = -1;
    else if(idx > this.state.pageTop) direction = 1;

    if(idx < 0) idx = this.images.length-1;
    else if(idx >= this.images.length) idx = 0;

    this.setState({pageTop: idx, direction});
  }

  nextImage() {
    this.goTo(this.state.pageTop+1);
  }
  prevImage() {
    this.goTo(this.state.pageTop-1);
  }

  onImageLoad() {
    this.setState({animate:true});
    setTimeout(() => this.setState({page:this.state.pageTop, animate:false}), 200);
  }

  render() {
    let topImageCls = style.topImage;
    if(this.state.animate) {
      if(this.state.direction == 1) topImageCls += " " + style.topImageLeft;
      else if(this.state.direction == -1) topImageCls += " " + style.topImageRight;
    }

    return (
      <div class={style.carousel} >
        <div class={style.imageContainer}>
          <img 
            class={style.image}
            src={this.images[this.state.page]} 
            onTouchStart={this.onTouchStart} 
            alt="smagsprøve"
          />

          {this.state.page != this.state.pageTop && 
          <img 
            onLoad={this.onImageLoad}
            class={topImageCls} 
            src={this.images[this.state.pageTop]}
            alt="smagsprøve"
          />}

          <img class={style.left}  src={icon_arrow} onClick={this.prevImage} />
          <img class={style.right} src={icon_arrow} onClick={this.nextImage} />
        </div>

        <div class={style.indicator}>
        {
          this.images.map((s, i) => {
            var cls = style.dot
            if(i == this.state.pageTop)
              cls += " " + style.current

            return <div class={cls} onClick={()=>this.goTo(i)}></div>
          })
        }
        </div>
      </div>
    );
  }
}