import { h } from "preact";

// @ts-ignore
import style from "./discountbanner.scss";
// @ts-ignore
import banner from "~assets/images/discountBanner.png";
// @ts-ignore
import karl from "~assets/images/krokodillenkarl/karl.png";
// @ts-ignore
import speech from "~assets/images/speechBubble.png";

export function DiscountBanner() {
  const bannerStyle = `background-image: url(${banner})`;

  return (
    <div class={style.discount} style={bannerStyle}>
      <h3 class={style.discountText}>Se vores udvalg af<br/>børnebøger her</h3>
      <img class={style.karl} src={karl} alt="Karl" />
      <div class={style.speech}>
        <img src={speech} alt="Taleboble" />
        <div>Hej med dig!</div>
      </div>
    </div>
  );
}