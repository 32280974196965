import {h, Component} from 'preact';
import { bookStore } from '../../bookstore';
import { ItemTable } from '../../components/itemtable/itemtable';
// @ts-ignore
import style from "./cart.scss";
import { Link } from '../../components/link/link';

class ShoppingCartState {
  coupon: string
}

export default class ShoppingCart extends Component<{}, ShoppingCartState> {
  constructor() {
    super();
    this.removeItem = this.removeItem.bind(this);
    this.onCouponChange = this.onCouponChange.bind(this);
    this.onCouponAdd = this.onCouponAdd.bind(this);
    this.state = {
      coupon: ""
    }
  }

  removeItem(idx:number) {
    bookStore.cart.remove(idx);
    this.setState({});
  }

  goToBestilling() {
    bookStore.link("/oplysninger");
  }

  onCouponChange(e) {
    const val = e.target.value.trim().toUpperCase();
    this.setState({coupon: val});
  }
  onCouponAdd() {
    if(this.state.coupon.length == 0) return;
    
    if(bookStore.shared.validateCoupon(this.state.coupon)) {
      bookStore.notifications.addInfo("Rabatkoden er indløst");
      bookStore.order.coupons.push(this.state.coupon);
    }
    else
      bookStore.notifications.addWarn("Rabatkoden er ikke gyldig", null, true);

    this.setState({coupon: ""});
  }

  render() {
    const cart = bookStore.cart;
    const couponStyle = this.state.coupon.length == 0 ? "" : style.validCoupon;

    return (
      <div class={style.cart}>
        <h1>Indkøbskurv</h1>
        {cart.items.length > 0 ?
        <div>
          <ItemTable onRemove={this.removeItem} linkItems={true}/>
          <div class={style.bottom}>
            <div class={style.coupon}>
              <input value={this.state.coupon} onInput={this.onCouponChange} placeholder="Rabatkode" />
              <div class={couponStyle} onClick={this.onCouponAdd}>Indløs</div>
            </div>
            <div class={style.bestilling} onClick={this.goToBestilling}>
              Gå til bestilling
            </div>
          </div>
        </div>
        :
        <div>
          <h4>Indkøbskurven er tom</h4>
          <Link url="/"><div class={style.seUdvalg}>Se udvalget her</div></Link>
        </div>
        }
      </div>
    );
  }
}