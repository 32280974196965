import {h, Component} from "preact";
import { Link } from "../../components/link/link";
// @ts-ignore
import style from "./omos.scss";
// @ts-ignore
import image_giraffe from "~/assets/images/characters/giraffe.png";

export default class FAQ extends Component {
    render() {
        return (
            <article class={style.omos}>
            <h1>Spørgsmål og svar</h1>
            <section>
                <div class={style.giraffe}>
                    <img src={image_giraffe} />
                </div>
                <section>
                    <h3>Levering</h3> 
                    <p>
                    Vores bøger leveres til en DAO365 pakkeshop efter dit valg. Du kan vælge hvor du vil afhente pakken når du bestiller.
                    </p>
                </section>
                <section>
                    <h3>E-bøger</h3>
                    <p>
                    Når du køber en E-bog modtager du dit personlige link til den side hvor du kan downloade e-bogen i både PDF og EPUB format.
                    Linket finder du i bunden af din ordrebekræftelse.
                    </p>
                </section>
                <section>
                    <h3>Fortrydelse af køb</h3>
                    <p>
                    Hvis du fortryder dit køb har du 14 dages fuld returret. Se mere <Link url="/betingelser"><div class={style.link}>her</div></Link>.
                    </p>
                </section>
                <section>
                    <h3>Reklamation</h3>
                    <p>
                    Hvis der er fejl eller mangler i din vare, så har du 2 års reklamationsret. Se mere <Link url="/betingelser"><div class={style.link}>her</div></Link>.
                    </p>
                </section>
            </section>
            </article>
        );
    }
}